<template>
  <v-app>
    <div style="padding-left:5vw; padding-right:5vw" v-if="loaded">
      <center>      
      <img
        :src="logoURL"
        width="100%"
        style="object-fit: cover; padding-left:5vw; padding-right:1vw;padding-top:5vh"
      />
        <h2 :style="getFontBold"><br />{{name}}</h2>
      </center>
    </div>
    <center v-if="!formSubmitted && !closed">
      <h3 :style="getFontRegular">
        <br />Please fill in the details below to submit your request<br />
      </h3>
    </center>
    <center v-if="!activeForm">
      <h3 :style="getFontRegular">
        <br />Sorry we have not been able to find that form, please check the URL<br />
      </h3>
    </center>
    <center v-if="closed && activeForm">
      <h3 :style="getFontRegular" v-if="loaded">
        <br />The {{name}} site has been closed<br />
      </h3>
    </center>
    <v-main transition="slide-x-transition">
      <v-container v-if="formSubmitted">
        <v-row>
          <v-col cols="12">
            <center>
              <h3 :style="getFontRegular">
                <br /><br />Thank you for completing the {{name}}. You may now close this page.
              </h3>
            </center>
          </v-col>
        </v-row>
      </v-container>
      <v-form v-model="valid" v-if="!formSubmitted && !closed">
        <v-container>
          <v-row>
            <v-col cols="12">
                <b><p v-html="privacy_data"></p></b>
            </v-col>

            <v-col cols="12">
              <div class="subTitle" :style="getFontRegular">
                1. CONTACT DETAILS
              </div>
            </v-col>
            <v-col cols="12">
              <v-text-field
                ref="firstname"
                v-model="firstname"
                :style="getFontRegular"
                :rules="nameRules"
                label="FIRST NAME *"
                required
                v-on:keyup.enter="$refs.lastname.focus()"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                ref="lastname"
                v-model="lastname"
                :style="getFontRegular"
                :rules="nameRules"
                label="SURNAME *"
                required
                v-on:keyup.enter="$refs.email.focus()"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                ref="email"
                v-model="email"
                :style="getFontRegular"
                :rules="emailRules"
                label="EMAIL ADDRESS *"
                required
                v-on:keyup.enter="$refs.legalname.focus()"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                ref="legalname"
                v-model="legalname"
                :style="getFontRegular"
                :rules="legalnameRules"
                label="FULL LEGAL NAME (As appears on driving licence) *"
                required
              ></v-text-field>
            </v-col>



            <v-col cols="12" v-if="field1Show">

              <v-text-field  v-if="field1Type == 'Free Text'"
                ref="field1Ref"
                v-model="field1Data"
                :style="getFontRegular"
                :rules="field1Rules"
                :label="field1Description"
                :required="field1Manditory"
              ></v-text-field>
              <v-select v-if="field1Type == 'Selection'"
                :items="field1Items"
                :style="getFontRegular"
                :rules="field1Rules"
                ref="field1Ref"
                v-model="field1Data"
                :label="field1Description"
              ></v-select>



              <v-menu  v-if="field1Type == 'Date Picker'"
                ref="field1RefMenu"
                v-model="menu1"

                :close-on-content-click="false"
                :return-value.sync="field1Data"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    ref="field1Ref"                  
                    v-model="field1Data"
                    :label="field1Description"
                    :style="getFontRegular"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="field1Data"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu1 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.field1RefMenu.save(field1Data)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>




            </v-col>







            <v-col cols="12" v-if="field2Show">

              <v-text-field  v-if="field2Type == 'Free Text'"
                ref="field2Ref"
                v-model="field2Data"
                :style="getFontRegular"
                :rules="field2Rules"
                :label="field2Description"
                required
              ></v-text-field>
              <v-select v-if="field2Type == 'Selection'"
                :items="field2Items"
                :style="getFontRegular"
                :rules="field2Rules"
                ref="field2Ref"
                v-model="field2Data"
                :label="field2Description"
              ></v-select>



              <v-menu  v-if="field2Type == 'Date Picker'"
                ref="field2RefMenu"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="field2Data"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    ref="field2Ref"                  
                    v-model="field2Data"
                    :label="field2Description"
                    :style="getFontRegular"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="field2Data"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu2 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.field2RefMenu.save(field2Data)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>




            </v-col>






            <v-col cols="12" v-if="field3Show">

              <v-text-field  v-if="field3Type == 'Free Text'"
                ref="field3Ref"
                v-model="field3Data"
                :style="getFontRegular"
                :rules="field3Rules"
                :label="field3Description"
                required
              ></v-text-field>
              <v-select v-if="field3Type == 'Selection'"
                :items="field3Items"
                :style="getFontRegular"
                :rules="field3Rules"
                ref="field3Ref"
                v-model="field3Data"
                :label="field3Description"
              ></v-select>



              <v-menu  v-if="field3Type == 'Date Picker'"
                ref="field3RefMenu"
                v-model="menu3"
                :close-on-content-click="false"
                :return-value.sync="field3Data"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    ref="field3Ref"                  
                    v-model="field3Data"
                    :label="field3Description"
                    :style="getFontRegular"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="field3Data"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu3 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.field3RefMenu.save(field3Data)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>




            </v-col>


            <v-col cols="12" v-if="field4Show">

              <v-text-field  v-if="field4Type == 'Free Text'"
                ref="field4Ref"
                v-model="field4Data"
                :style="getFontRegular"
                :rules="field4Rules"
                :label="field4Description"
                required
              ></v-text-field>
              <v-select v-if="field4Type == 'Selection'"
                :items="field4Items"
                :style="getFontRegular"
                :rules="field4Rules"
                ref="field4Ref"
                v-model="field4Data"
                :label="field4Description"
              ></v-select>



              <v-menu  v-if="field4Type == 'Date Picker'"
                ref="field4RefMenu"
                v-model="menu4"
                :close-on-content-click="false"
                :return-value.sync="field4Data"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    ref="field4Ref"                  
                    v-model="field4Data"
                    :label="field4Description"
                    :style="getFontRegular"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="field4Data"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu4 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.field4RefMenu.save(field4Data)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>




            </v-col>


            <v-col cols="12" v-if="field5Show">

              <v-text-field  v-if="field5Type == 'Free Text'"
                ref="field5Ref"
                v-model="field5Data"
                :style="getFontRegular"
                :rules="field5Rules"
                :label="field5Description"
                required
              ></v-text-field>
              <v-select v-if="field5Type == 'Selection'"
                :items="field5Items"
                :style="getFontRegular"
                :rules="field5Rules"
                ref="field5Ref"
                v-model="field5Data"
                :label="field5Description"
              ></v-select>



              <v-menu  v-if="field5Type == 'Date Picker'"
                ref="field5RefMenu"
                v-model="menu5"
                :close-on-content-click="false"
                :return-value.sync="field5Data"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    ref="field5Ref"                  
                    v-model="field5Data"
                    :label="field5Description"
                    :style="getFontRegular"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="field5Data"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu5 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.field5RefMenu.save(field5Data)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>




            </v-col>










            <v-col cols="12">
              <div class="subTitle" id="signaturePadLabel" :style="getFontRegular">
                2. LEGAL
              </div>
              <p :style="getFontRegular">
                <br />
                I confirm that I have read and understand the <a v-on:click="dialog = !dialog">BMW Legal Disclaimer (Click here to view)</a>. Please sign on the signature pad below.
              </p>
            </v-col>
            <v-dialog v-model="dialog" scrollable max-width="90vw">
              <v-card>
                <v-card-title :style="getFontRegular">PLEASE READ</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 90vh;" :style="getFontRegular">
                  <BMWTerms :terms="terms"/>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn color="blue darken-1" text @click="dialog = false"
                    >CLOSE</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-col cols="12">
              <label for="input-12" class="v-label theme--light" :style="getFontRegular"
                >YOUR SIGNATURE *</label
              >
              <div
                id="signaturePadDiv"
                style="border:1px solid #ccc;width:100%;height:20vh;"
              >
                <VueSignaturePad
                  width="100%"
                  height="20vh"
                  ref="signaturePad"
                />
                <div class="v-text-field__details" v-if="signatureInValid">
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div class="v-messages__message" :style="getFontRegular">
                        Signature is required
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <a @click="undo" :style="getFontRegular">UNDO</a>
                </div>
              </div>
              <br />
            </v-col>
            <v-col cols="12">
              <div class="subTitle" :style="getFontRegular">
                3. DRIVING
              </div>
              <p :style="getFontRegular">
                <br />Please take/upload a front and rear image of your current
                Drivers Licence. Please ensure the image is clear and readable.
              </p>
            </v-col>

            <v-col cols="12">
              <v-file-input
                show-size
                counter
                :rules="licenseFrontRules"
                :style="getFontRegular"
                ref="licenseFront"
                v-model="licenseFront"
                accept="image/*"
                label="UPLOAD THE FRONT IMAGE (.PNG or .JPG) *"
              ></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-file-input
                show-size
                counter
                :rules="licenseBackRules"
                :style="getFontRegular"
                ref="licenseBack"
                v-model="licenseBack"
                accept="image/*"
                label="UPLOAD THE REAR IMAGE (.PNG or .JPG) *"
              ></v-file-input>
            </v-col>
            <v-col cols="12" v-if="!hidedvla">
              <p :style="getFontRegular">
                Please visit the DVLA website
                <a
                  href="https://www.gov.uk/view-driving-licence"
                  target="_blank"
                  ><b>here</b></a
                >
                to generate your 8 digit check code. Once you have your check
                code form, download it as PDF or an image and please upload it
                below. <b>THIS IS ONLY FOR UK LICENCE HOLDERS.</b>
              </p>
            </v-col>

            <v-col cols="12" v-if="!hidedvla">
              <v-file-input
                show-size
                counter
                ref="dvlaDocument"
                :style="getFontRegular"
                v-model="dvlaDocument"
                label="UPLOAD DVLA PDF"
              ></v-file-input>
                <div class="v-text-field__details" v-if="dvlaInValid">
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div class="v-messages__message" :style="getFontRegular">
                        Sorry the DVLA file is empty
                      </div>
                    </div>
                  </div>
                </div>

            </v-col>
            <v-col cols="12">
              <v-alert :value="alert" prominent type="error">
                Sorry there seems to have been a problem sending your data
                please try again later
              </v-alert>
            </v-col>
            <v-col cols="12">
              <center>
                <v-btn
                  color="primary"
                  @click="submitForm()"
                  style="width:100%"
                  :style="getFontRegular"
                  :disabled="saving"
                  >{{ saving ? "SENDING DATA" : "SUBMIT" }}</v-btn
                >
              </center>
            </v-col>
            <v-col cols="12">
            <center><small><a :href="policy_url" target="_blank">Privacy Policy</a></small></center><br/><br/><br/>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-main>
  </v-app>
</template>

<script>
import BMWTerms from "@/components/BMWTerms";
import axios from "axios";
import Moment from "moment";

export default {
  name: "App",
  components: {
    BMWTerms,
  },
  data: () => ({
    //baseUrl: 'https://bmwdatacapture.test7.mediabasedirect.com/',
    //baseUrl: 'https://leadr.test/',
    baseUrl: 'https://bmwdatacapture.mediabasedirect.com/',
    closed: true,
    closeDateTime: "2020-09-29 14:00:00",
    event_code: "250113",
    event_brand: 'BMW',
    name:'',
    logo:'',
    hidedvla:false,
    policy_url:'https://www.bmw.co.uk/en/footer/legal/privacy-policy.html',
    terms:'-',
    brand:1,
    privacy_data:'',
    menu:'',
    menu1:'',
    menuLeave:'',
    
    saving: false,
    loaded:false,
    alert: false,
    formSubmitted: false,
    signatureInValid: false,
    dvlaInValid: false,
    dialog: false,
    valid: false,
    validForm: true,
    activeForm: false,
    signatueOption: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgb(255,255,255)"
    },
    firstname: "",
    lastname: "",
    email: "",
    legalname: "",

    indmnityID: "",
    nameRules: [v => !!v || "Name is required"],
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+/.test(v) || "E-mail must be valid"
    ],
    legalnameRules: [v => !!v || "Full Legal Name is required"],

    field1Show: true,
    field1Manditory: true,
    field1Type: 'Free Text',
    field1Description: "PLEASE SELECT THE DATE OF YOUR EXPERIENCE *",
    field1Rules: [v => !!v || "This data is required"],
    field1Data: "",
    field1Source: "",
    field1Items: [""],

    field2Show: true,
    field2Manditory: true,
    field2Type: 'Free Text',
    field2Description: "PLEASE SELECT THE DATE OF YOUR EXPERIENCE *",
    field2Rules: [v => !!v || "This data is required"],
    field2Data: "",
    field2Source: "",
    field2Items: [""],

    field3Show: true,
    field3Manditory: true,
    field3Type: 'Free Text',
    field3Description: "PLEASE SELECT THE DATE OF YOUR EXPERIENCE *",
    field3Rules: [v => !!v || "This data is required"],
    field3Data: "",
    field3Source: "",
    field3Items: [""],


    field4Show: true,
    field4Manditory: true,
    field4Type: 'Free Text',
    field4Description: "",
    field4Rules: [v => !!v || "This data is required"],
    field4Data: "",
    field4Source: "",
    field4Items: [""],


    field5Show: true,
    field5Manditory: true,
    field5Type: 'Free Text',
    field5Description: "",
    field5Rules: [v => !!v || "This data is required"],
    field5Data: "",
    field5Source: "",
    field5Items: [""],


    leaveShow: true,
    leaveType: 'Free Text',
    leaveDescription: "PLEASE SELECT THE LOCATION OF YOUR EXPERIENCE *",
    leave: "",
    leaveRules: [v => !!v || "Where to leave car is required"],
    leaveItems: ["Runnymede Hotel", "Coworth Park"],
    licenseFront: null,
    licenseFrontRules: [
      v => !!v || "Picture of the Front of your Driving Licence is required"
    ],
    licenseBack: null,
    licenseBackRules: [
      v => !!v || "Picture of the Back of your Driving Licence is required"
    ],
    dvlaDocument: null,
    dvlaDocumentRules: [v => !!v || "DLVA Check code PDF is required"]
  }),

  mounted() {



    if (this.$root.$router.currentRoute.params['id']) {
      this.indmnityID = this.$root.$router.currentRoute.params['id'];
      this.activeForm = true;
    } else {
      this.activeForm = false;
      this.closed = true;
    }

    this.getData();



  },
  computed: {
    logoURL() {
      return this.baseUrl + this.logo;
      // The path could be '../assets/img.png', etc., which depends on where your vue file is
    },
    getFontRegular() {
      return 'font-family: "' + this.event_brand + ' Type Next Regular" !important;'
    },
    getFontBold() {
      return 'font-family: "' + this.event_brand + ' Type Next Bold" !important;'
    }
  },  
  methods: {

    getData() {
      axios.get(this.baseUrl + '/indemnitycapture/' + this.indmnityID)
          .then(
              rsp => {


                  this.closeDateTime = rsp.data.indemnityForm.end_date;
                  var closeDate = Moment(this.closeDateTime);    


                  if (closeDate < Moment()) {
                    this.closed = true;
                  } else {
                    this.closed = false;
                  }     
                  

                  this.name = rsp.data.indemnityForm.form_name;
                  if (rsp.data.indemnityForm.form_title != '') {
                    this.name = rsp.data.indemnityForm.form_title;
                  }
                  this.brand = rsp.data.indemnityForm.brand;
                  this.logo = rsp.data.indemnityForm.logo;
                  //this.hidedvla = rsp.data.indemnityForm.hide_dlva;

                  if ( rsp.data.indemnityForm.policy_url != '') {
                  //  this.policy_url = rsp.data.indemnityForm.policy_url;
                  }
                  

                  this.field1Show = rsp.data.indemnityForm.fields.field1;
                  this.field1Manditory = rsp.data.indemnityForm.fields.field1_manditory;
                  this.field1Type = rsp.data.indemnityForm.fields.field1_type;
                  this.field1Description = rsp.data.indemnityForm.fields.field1_text;

                  if (this.field1Manditory) {
                    this.field1Description = this.field1Description + ' *';
                  }
                  this.field1Items =  rsp.data.indemnityForm.fields.field1_options;

                  this.field2Show = rsp.data.indemnityForm.fields.field2;
                  this.field2Manditory = rsp.data.indemnityForm.fields.field2_manditory;
                  this.field2Type = rsp.data.indemnityForm.fields.field2_type;
                  this.field2Description = rsp.data.indemnityForm.fields.field2_text;

                  if (this.field2Manditory) {
                    this.field2Description = this.field2Description + ' *';
                  }
                  this.field2Items =  rsp.data.indemnityForm.fields.field2_options;

                  this.field3Show = rsp.data.indemnityForm.fields.field3;
                  this.field3Manditory = rsp.data.indemnityForm.fields.field3_manditory;
                  this.field3Type = rsp.data.indemnityForm.fields.field3_type;
                  this.field3Description = rsp.data.indemnityForm.fields.field3_text;

                  if (this.field3Manditory) {
                    this.field3Description = this.field3Description + ' *';
                  }
                  this.field3Items =  rsp.data.indemnityForm.fields.field3_options;

                  this.field4Show = rsp.data.indemnityForm.fields.field4;
                  this.field4Manditory = rsp.data.indemnityForm.fields.field4_manditory;
                  this.field4Type = rsp.data.indemnityForm.fields.field4_type;
                  this.field4Description = rsp.data.indemnityForm.fields.field4_text;

                  if (this.field4Manditory) {
                    this.field4Description = this.field4Description + ' *';
                  }
                  this.field4Items =  rsp.data.indemnityForm.fields.field4_options;

                  this.field5Show = rsp.data.indemnityForm.fields.field5;
                  this.field5Manditory = rsp.data.indemnityForm.fields.field5_manditory;
                  this.field5Type = rsp.data.indemnityForm.fields.field5_type;
                  this.field5Description = rsp.data.indemnityForm.fields.field5_text;

                  if (this.field5Manditory) {
                    this.field5Description = this.field5Description + ' *';
                  }
                  this.field5Items =  rsp.data.indemnityForm.fields.field5_options;


                  this.privacy_data = rsp.data.indemnityForm.privacy;
                  this.terms =  rsp.data.indemnityForm.disclaimer;

                  this.event_code = rsp.data.indemnityForm.event_code;

                  console.log('rsp.data.indemnityForm');
                  console.log(rsp.data.indemnityForm.event_brand.code);
                  this.event_brand = rsp.data.indemnityForm.event_brand.code;

                  document.title = this.event_brand + ' UK Legal Disclaimer and License Check';
                  
                  if (this.event_brand == 'MINI') {
                    const favicon = document.getElementById("favicon");
                    favicon.href = "/mini_favicon.ico";                  
                  }

                  this.loaded = true;                  

              }
          )
    },

    submitForm() {
      this.validForm = true;
      this.signatureInValid = false;
      this.dvlaInValid = false;

      console.log(this.dvlaDocument);
      if (this.dvlaDocument !== null && this.validForm) {
        console.log(this.dvlaDocument.size);
        if (this.dvlaDocument.size < 1) {
          console.log('invalid pdf zis');
          this.validForm = false;
          this.dvlaInValid = true;
          this.$refs.dvlaDocument.focus();
        }
      }


      if (this.firstname == "" && this.validForm) {
        this.validForm = false;
        this.$refs.firstname.focus();
      }
      if (this.lastname == "" && this.validForm) {
        this.validForm = false;
        this.$refs.lastname.focus();
      }
      if (this.email == "" && this.validForm) {
        this.validForm = false;
        this.$refs.email.focus();
      }
      if (this.legalname == "" && this.validForm) {
        this.validForm = false;
        this.$refs.legalname.focus();
      }

      if (this.field1Show && this.field1Manditory) {
        if (this.field1Data == "" && this.validForm) {
          this.validForm = false;
          console.log(this.$refs);
          this.$refs.field1Ref.focus();
        }
      }

      if (this.field2Show && this.field2Manditory) {
        if (this.field2Data == "" && this.validForm) {
          this.validForm = false;
          console.log(this.$refs);
          this.$refs.field2Ref.focus();
        }
      }

      if (this.field3Show && this.field3Manditory) {
        if (this.field3Data == "" && this.validForm) {
          this.validForm = false;
          console.log(this.$refs);
          this.$refs.field3Ref.focus();
        }
      }

      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty && this.validForm) {
        this.validForm = false;
        const el = this.$el.querySelector("#signaturePadLabel");
        if (el) {
          el.scrollIntoView();
        }
        this.signatureInValid = true;
      }

      console.log(isEmpty);
      console.log(data);

      var signaturedata = data;

      if (this.licenseFront == null && this.validForm) {
        this.validForm = false;
        this.$refs.licenseFront.focus();
      }

      if (this.licenseBack == null && this.validForm) {
        this.validForm = false;
        this.$refs.licenseBack.focus();
      }

      //if (this.dvlaDocument == null && this.validForm) {
      //  this.validForm = false;
      //  this.$refs.dvlaDocument.focus();
      //}



      console.log(signaturedata);

      if (this.validForm) {
        // form is valid lets send the form

        this.saving = true;
        this.alert = false;

        //var url = "http://localhost/capture/indemnityextended";
        //var url = "https://bmwdatacapture.test7.mediabasedirect.com/capture/indemnityextended";

        var url =
          this.baseUrl + "capture/indemnityextended";
         

        let fd = new FormData();
        fd.append("signature", signaturedata);
        fd.append("license_front", this.licenseFront);
        fd.append("license_back", this.licenseBack);
        fd.append("dvla_document", this.dvlaDocument);
        fd.append("customer_firstname", this.firstname);
        fd.append("customer_lastname", this.lastname);
        fd.append("customer_email", this.email);
        fd.append("customer_legal_name", this.legalname);
        fd.append("collect", this.field1Data);
        fd.append("collectWhen", this.field2Data);
        fd.append("leave", this.field3Data);
        fd.append("field4", this.field4Data);
        fd.append("field5", this.field5Data);
        fd.append("event_code", this.event_code);
        fd.append("indemnity_id", this.indmnityID);

        axios
          .post(url, fd)
          .then(() => {
            this.formSubmitted = true;
            this.saving = false;
          })
          .catch(error => {
            console.log({ ...error });
            this.alert = true;
            this.saving = false;
          });
      }
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    }
  }
};
</script>

<style>
@font-face {
  font-family: "BMW Type Next Bold";
  src: url("./assets/BMWTypeNextLatinTT-Bold.woff") format("woff");
}
@font-face {
  font-family: "BMW Type Next Regular";
  src: url("./assets/BMWTypeNextLatinTT-Regular.woff") format("woff");
}
@font-face {
  font-family: "BMW Type Next Regular";
  src: url("./assets/BMWTypeNextLatinTT-Light.woff") format("woff");
}

@font-face {
  font-family: "MINI Type Next Bold";
  src: url("./assets/mini_serif-bold-web.woff") format("woff");
}

@font-face {
  font-family: "MINI Type Next Regular";
  src: url("./assets/mini_serif-regular-web.woff") format("woff");
}

@font-face {
  font-family: "BMW Motorrad Type Next Bold";
  src: url("./assets/BMWMotorradW05-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "BMW Motorrad Type Next Regular";
  src: url("./assets/BMWMotorradW05-Regular.woff2") format("woff2");
}



.subTitle {
  font-family: "MOTORAD Type Next Bold" !important;
  border-bottom: 1px solid #ccc;
  font-size: 1.3em;
  display: block;
  width: 100%;
}
.v-application--wrap {
  max-width: 800px !important;
  margin-left: auto;
  margin-right: auto;
}
.v-messages__message {
  color: red;
  font-size: 1.2em;
  font-weight: bold;
}
</style>
